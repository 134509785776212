// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These 
// styles are for the entire app and not just one component. Additionally, this 
// file can hold Sass mixins, functions, and placeholder classes to be imported 
// and used throughout the application.

$gruen: #38954B;
$blau: #0277bd;

// Alte Material Design Icons
// @import '../assets/fonts';

@import 'material-icons/iconfont/filled.css';
@import 'material-icons/iconfont/outlined.css';

// :root {
//     --ion-color-primary: #CDDEED;
//     --ion-color-primary-rgb: 205,222,237;
//     --ion-color-primary-contrast: #000000;
//     --ion-color-primary-contrast-rgb: 0,0,0;
//     --ion-color-primary-shade: #b4c3d1;
//     --ion-color-primary-tint: #d2e1ef;

//     --ion-color-secondary: #C3F3F9;
//     --ion-color-secondary-rgb: 195,243,249;
//     --ion-color-secondary-contrast: #000000;
//     --ion-color-secondary-contrast-rgb: 0,0,0;
//     --ion-color-secondary-shade: #acd6db;
//     --ion-color-secondary-tint: #c9f4fa;

//     --ion-color-tertiary: #5260ff;
//     --ion-color-tertiary-rgb: 82,96,255;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255,255,255;
//     --ion-color-tertiary-shade: #4854e0;
//     --ion-color-tertiary-tint: #6370ff;

//     --ion-color-success: #D7EDD7;
//     --ion-color-success-rgb: 215,237,215;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0,0,0;
//     --ion-color-success-shade: #bdd1bd;
//     --ion-color-success-tint: #dbefdb;

//     --ion-color-warning: #FBEBD3;
//     --ion-color-warning-rgb: 251,235,211;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0,0,0;
//     --ion-color-warning-shade: #ddcfba;
//     --ion-color-warning-tint: #fbedd7;

//     --ion-color-danger: #FBD0D0;
//     --ion-color-danger-rgb: 251,208,208;
//     --ion-color-danger-contrast: #000000;
//     --ion-color-danger-contrast-rgb: 0,0,0;
//     --ion-color-danger-shade: #ddb7b7;
//     --ion-color-danger-tint: #fbd5d5;

//     --ion-color-dark: #222428;
//     --ion-color-dark-rgb: 34,36,40;
//     --ion-color-dark-contrast: #ffffff;
//     --ion-color-dark-contrast-rgb: 255,255,255;
//     --ion-color-dark-shade: #1e2023;
//     --ion-color-dark-tint: #383a3e;

//     --ion-color-medium: #92949c;
//     --ion-color-medium-rgb: 146,148,156;
//     --ion-color-medium-contrast: #ffffff;
//     --ion-color-medium-contrast-rgb: 255,255,255;
//     --ion-color-medium-shade: #808289;
//     --ion-color-medium-tint: #9d9fa6;

//     --ion-color-light: #f4f5f8;
//     --ion-color-light-rgb: 244,245,248;
//     --ion-color-light-contrast: #000000;
//     --ion-color-light-contrast-rgb: 0,0,0;
//     --ion-color-light-shade: #d7d8da;
//     --ion-color-light-tint: #f5f6f9;
//   }

:root {
    --ion-color-primary: #337AB7;
    --ion-color-primary-rgb: 51, 122, 183;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #2d6ba1;
    --ion-color-primary-tint: #4787be;

    --ion-color-secondary: #17BADA;
    --ion-color-secondary-rgb: 23, 186, 218;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #14a4c0;
    --ion-color-secondary-tint: #2ec1de;

    --ion-color-success: #5CB85C;
    --ion-color-success-rgb: 92, 184, 92;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #51a251;
    --ion-color-success-tint: #6cbf6c;

    --ion-color-warning: #f0ad4e;
    --ion-color-warning-rgb: 240, 173, 78;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #d39845;
    --ion-color-warning-tint: #f2b560;
}

ion-toolbar {
    --background: #0277bd;
    color: white;

    .sidemenu-title {
        height: 120px;
    }
}

.button-row {
    margin: 10px;
}

.nowrap {
    white-space: nowrap;
}

.align-right {
    text-align: right;
}

.section {
    position: relative;

    border: 1px solid #ccc;
    border-left: 0;
    border-right: 0;

    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
    padding: 20px;

    // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    // box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
    border: 1px solid #ddd;
    border-radius: 0;

    // Für was ist das?
    // transition: transform 0.5s cubic-bezier(0.12, 0.72, 0.29, 1) 0s, -webkit-transform 0.5s cubic-bezier(0.12, 0.72, 0.29, 1) 0s;
    background-color: white;
    --ion-background-color: white;

    ion-item {
        // Kein Padding im Item selbst. Das Padding der Secion ist ausreichend. Kopiert aus ion-no-padding
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.container-item-no-padding {
    ion-item {
        // Kein Padding im Item selbst. Das Padding der Secion ist ausreichend. Kopiert aus ion-no-padding
        --padding-start: 0;
        // --padding-end: 0;
        // --padding-top: 0;
        // --padding-bottom: 0;
        // padding-left: 0;
        // padding-right: 0;
        // padding-top: 0;
        // padding-bottom: 0;
    }
}

ion-content.section-content {
    --ion-background-color: #e7e7e7;
    --padding-start: 5px;
    --padding-end: 5px;
    --padding-top: 5px;
    --padding-bottom: 5px;

    .section {
        border: 0;
        border-radius: 0;
    }
}

.design-v2 {
    ion-content.section-content {
        .section {
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 10px;
            margin-bottom: 5px;
            box-shadow: none;

            h1 {
                margin-top: 0;
                font-size: 14px;
                // color: #eb6e0a; // wird über Code gesetzt
                font-weight: 400;
            }

            h1.kommissionieren-palette-titel {
                font-size: 20px;
            }
        }
    }
}


h2.auftragsitem-name {
    font-weight: 500;
}

.design-v3 {

    // ion-button.button-large {
    //     border: none !important;
    // }

    // .section {
    //     background-color: #EDE9ED;
    //     margin-left: -10px !important;
    //     margin-right: -10px !important;
    //     padding-left: 25px;
    //     padding-right: 25px;
    //     box-shadow: none;
    // }

    // ion-content {
    //     --ion-background-color: #EDE9ED;
    //     --ion-item-background: #EDE9ED;

    //     .searchbar-input {
    //         background: white;
    //     }

    //     ion-select, ion-input {
    //         background: white;

    //         label {
    //             background-color: #EDE9ED;
    //         }
    //     }

    //     ion-list {
    //         ion-item {
    //             --ion-item-background: white;
    //             --padding-start: 10px;
    //         }
    //     }


    //     ion-textarea {
    //         background-color: white;
    //     }
    // }

    // ion-content.ion-content-list {
    //     --ion-background-color: #EDE9ED;
    // }

    .auftragsitem-name {
        font-weight: 500;
    }

    ion-button.toggle-button {
        height: 48px;
        width: 49px;
        border: 0 !important;
        box-shadow: none;
        border-radius: 100px;
        display: inline-block;
        overflow: hidden;
        font-size: 32px;
        --padding-start: 10px;
        --padding-end: 10px;
    }

    // ion-icon.toggle-button {
    //     height: 48px;
    //     width: 49px;
    //     border: 0 !important;
    //     box-shadow: none;
    //     border-radius: 100px;
    //     display: inline-block;
    //     overflow: hidden;
    //     font-size: 32px;
    // }

    // ion-icon.toggle-button.color-light {
    //     background-color: transparent;
    //     color:black;
    // }

    // ion-icon.toggle-button.color-light {
    //     background-color: transparent;
    //     color:black;
    // }

    // ion-icon.toggle-button.color-light {

    // }
}

ion-content.section-content-white {
    .section {
        box-shadow: none;
        border: 0;
        margin-bottom: 0;
    }
}

.pt0 {
    padding-top: 0;
}

.pb0 {
    padding-bottom: 0;
}

// .theme-modern {
//     .section {
//         padding: 20px;
//     }
// }

// .theme-kompakt {
//     .section {
//         margin: 0px !important;
//     }
// }


h1 {
    margin-top: 0;
    font-size: 18px;
    // color: #666;
}

// Für Auftrag-Lieferung
ion-item-divider {
    min-height: 15px;
    border-bottom: 0;
    border-top: 1px solid #ccc;
    --color: black;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-25 {
    margin-top: 35px;
}

.mt-35 {
    margin-top: 35px;
}

.pb-0 {
    padding-bottom: 0;
}

.pr-15 {
    padding-right: 15px;
}

ion-footer {
    ion-toolbar {
        // --min-height: 32px;
        --background: white;
        color: #333;
    }
}

.full-height-item {
    height: 100%;
    width: 100%;
}

.full-height-textarea {
    textarea {
        height: 100%;
        width: 100%;
    }
}

.ionic-selectable-value {
    // geht noch nicht
    padding-top: 4px;
    padding-bottom: 4px;
}

.ionic-selectable {
    max-width: 100% !important;
    width: calc(100% - 120px);
}

ionic-selectable {
    min-height: 30px;

    .ionic-selectable-value {
        padding-bottom: 6px;
        padding-top: 6px;

        --padding-start: 10px;
    }

    .ionic-selectable-icon-inner {
        top: 14px;
    }
}

.design-standard,
.design-v2 {
    .input-button {
        border: 1px solid #ddd;
        height: 36px;
        margin-left: 0;
        margin-right: 0;
        border-left: 0;
        --border-radius: 0;
        --box-shadow: none;
    }
}

.logo-img {
    display: block;
    max-height: 50px;
    // background-color: white;
    margin-top: 10px;
}

ion-list.transparent-list {
    background-color: transparent;
    // --background: transparent;

    ion-item {
        // background-color: transparent;
        --background: transparent;
    }
}

// .btn-icon-only {
//     width: 38px;
//     height: 34px;
//     min-width: 38px !important;
// }

.icon-transportauftrag {
    background-image: url("/assets/icon/dispatch.png");
}

ion-alert.input-alert {
    align-items: start !important;
    padding-top: 50px;
}

.m15 {
    margin: 15px;
}

.p15 {
    padding: 15px;
}

ion-content.loading {
    text-align: center;

    ion-spinner {
        // color: #999;
        width: 100px;
        height: 100%;
    }
}

.kennzeichen {
    position: relative;
    margin-top: 18px;
    font-size: 24px;
    border: 1px solid rgb(31, 27, 27);
    padding: 5px;

    span.blau {
        display: inline-block;
        background-color: #003399;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 36px;
        color: white;
        text-align: center;
        padding-top: 5px;

        img {
            margin-top: 0px;
        }
    }

    span.kennzeichen-text {
        position: relative;
        left: 40px
    }
}

.overlay-spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    text-align: center;
    opacity: 0.7;

    // Über ion-modal. Das hat z-index 20003
    z-index: 20500;

    ion-spinner {
        // color: #999;
        width: 100px;
        height: 100%;
    }
}

.overlay-buttons {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
    text-align: center;
    z-index: 99;
    display: table-cell;
    vertical-align: middle;

    .overlay-buttons-backgrop {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: white;
        opacity: 0.7;
    }

    .button-container {
        display: inline-block;
        background-color: white;
        padding: 15px;
        border: 1px solid #ddd;
        top: 50%;
        transform: translateY(-50%);
        position: relative;
        width: 95%;
        max-width: 300px;
        // --box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2),0 2px 2px 0 rgba(0,0,0,0.14),0 1px 5px 0 rgba(0,0,0,0.12);

        ion-button {
            width: 100%;
            height: 64px;
        }
    }

}

a.auftrag-neu {
    background-color: lightgreen;
}

ion-item.auftrag-ausgeblendet {
    background-color: #ddd;
    --background: #ddd;
}

.info1 {
    white-space: normal;
    overflow: hidden;

    span {
        background-color: yellow;
    }
}

.info2 {
    white-space: normal;
    overflow: hidden;
}

.info3 {
    white-space: normal;
    overflow: hidden;
}

ion-list-header {
    font-size: 20px;
}

.sicherheitshinweis {
    --max-width: 95%;
}

.section.list-header-section {
    padding-left: 15px;

}

.design-standard,
.design-v2 {
    ion-button {
        text-transform: none;
        font-size: 16px;

        --border-radius: 0;
        --border-width: 1px;
        --border-color: #000;
        --box-shadow: none;
    }

    ion-button.toggle-button,
    ion-button.status-button,
    ion-button.nfc-button,
    ion-button.datum-button,
    ion-button.barcode-button,
    ion-button.button-border {
        // --border-radius: 0;
        // --border-width: 1px;
        // --border-color: #000;
        // --box-shadow: none;
        border: 1px solid #ccc !important;
    }
}

ion-button.main-item {
    text-transform: none;
}

ion-button.datum-button {
    --padding-start: 8px;
    --padding-end: 8px;
    width: 48px;
}

ion-button.icon-button-with-text {
    ion-icon {
        margin-right: 10px;
    }
}

ion-button.button-large {
    height: 64px;
}

ion-button.button-default {
    height: 48px;
}

.design-standard,
.design-v2 {
    ion-button.button-large {
        border: 1px solid #ccc !important;
    }

    ion-button.button-default {
        border: 1px solid #ccc !important;
    }
}

ion-button.button-outline {
    border: none;
}

ion-button.delete-item-button {
    width: 32px;
    margin-left: 2px;
    --padding-start: 0;
    --padding-end: 0;
}

ion-button {
    // Ist notwendig, da sonst eine Border auf der rechten Seite von vielen Buttons fehlt
    overflow: hidden;
}

ion-item.no-padding-end {
    --padding-end: 0;
    --inner-padding-end: 0;
}

.alert-message {
    p:first-child {
        margin-top: 0;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.alert-head.sc-ion-alert-md+.alert-message.sc-ion-alert-md {
    padding-bottom: 0;
}

.alert-message.sc-ion-alert-md {
    padding-bottom: 15px !important;
    white-space: pre-line;
}

.text-uppercase {
    text-transform: uppercase;
}

.segment-disabled.sc-ion-segment-md-h {
    opacity: 1;
}

.footer-space {
    // Platz am unteren Rand, damit eventuelle FAB-Buttons angezeigt werden können und nichts
    // wichtiger überdecken
    height: 80px;
}

.archiv-message {
    text-align: center;
    background-color: orange;
    font-size: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 5px;
}

.schreibgeschuetzt {}

table.app-table {
    tr {
        border-bottom: 1px solid #ccc;
    }

    tr.no-bottom-border {
        border-bottom: 0;
    }

    tr:last-of-type {
        border-bottom: none;
    }

    tr.summe {
        font-weight: bold;
    }

    td {
        padding: 2px;
        // vertical-align: top;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    tfoot {
        tr {
            border-bottom: none;
        }
    }

    ion-textarea {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        // --padding-bottom: 0;

        textarea {
            min-height: 20px;
        }
    }
}

// IOS-Alerts
.alert-wrapper.sc-ion-alert-ios {
    border-radius: 3px;
}

.alert-sub-title.sc-ion-alert-ios {
    font-size: 16px;
    white-space: pre-line;
}

.alert-input-group.sc-ion-alert-ios,
.alert-message.sc-ion-alert-ios {
    font-size: 16px;
}

.sc-ion-loading-md-h {
    font-size: 16px;
}

.alert-button.sc-ion-alert-ios {
    height: 56px;
}

ion-item-group.auftragsposition {
    >ion-item-divider {
        // background-color: #ddd;
        color: black;
        font-size: 18px;
    }

    >ion-item-group {
        >ion-item-divider {
            ion-label {
                color: #666 !important;
            }
        }
    }

    margin-bottom: 30px !important;
}

ion-input.ion-invalid,
ion-input.invalid-value {
    background-color: #FFBFBF !important;
    color: red;
}

ion-datetime.invalid-value {
    background-color: #FFBFBF !important;
    color: red;
}

.section.section-invalid {
    // background-color: #FFBFBF !important;
}

.nicht-vollstaendig {
    color: red;
    float: right;
    font-weight: 400;
}

.item-label-stacked {

    ion-input.ion-invalid,
    ion-input.invalid-value {
        // Für Input-Felder im "stacked"-Layout soll der Hintergrund nicht rot sein.
        // Das sieht scheisse aus.
        background-color: transparent;
    }
}

// ion-input.ion-invalid.has-value,
// ion-input.invalid-value.has-value {
//     .ionic-selectable-inner {
//         background-color: #FFBFBF;
//         color: red;
//     }
// }

ionic-selectable.ng-invalid {
    .ionic-selectable-inner {
        background-color: #FFBFBF;
        color: red;
    }
}

ion-radio-group.ion-invalid {
    ion-radio {
        background-color: #FFBFBF;
    }
}

ion-select.ion-invalid {
    background-color: #FFBFBF;
}

.button-md {
    margin-bottom: 10px;
    height: 48px;
}

.toggle-icon {
    border-radius: 0;
}

.schriftgroesse-gross {
    font-size: 16px;

    p {
        font-size: 16px;
    }
}

.schriftgroesse-sehr-gross {
    font-size: 18px;

    p {
        font-size: 18px;
    }
}

.schriftgroesse-gross {
    ion-button.main-item {
        .item-content {
            h1 {
                font-size: 18px !important;
            }
        }
    }

    .sc-ion-label-md-s h2 {
        font-size: 18px !important;
    }

    .sc-ion-label-md-s p {
        font-size: 16px !important;
    }

    .item.sc-ion-label-md-h,
    .item .sc-ion-label-md-h {
        font-size: 18px !important;
    }

    h1 {
        font-size: 20px !important;
    }

    h2 {
        font-size: 18px !important;
    }

    ion-button {
        font-size: 16px;
    }

    .action-sheet-button.sc-ion-action-sheet-md {
        font-size: 18px !important;
    }

    .native-input.sc-ion-input-md {
        font-size: 18px !important;
    }

    ionic-selectable {
        font-size: 18px !important;
    }

    .alert-input-group,
    .alert-message {
        font-size: 18px !important;
    }

    .nachricht-absender,
    .nachricht-datum {
        font-size: 15px !important;
    }

    .nachricht-text {
        font-size: 18px !important;
    }
}

.schriftgroesse-sehr-gross {
    ion-button.main-item {
        .item-content {
            h1 {
                font-size: 20px !important;
            }
        }

        ion-badge {
            font-size: 24px;
        }
    }

    .sc-ion-label-md-s h2 {
        font-size: 20px !important;
    }

    .sc-ion-label-md-s p {
        font-size: 18px !important;
    }

    .item.sc-ion-label-md-h,
    .item .sc-ion-label-md-h {
        font-size: 20px !important;
    }

    h1 {
        font-size: 22px !important;
    }

    h2 {
        font-size: 20px !important;
    }

    ion-button {
        font-size: 18px;
    }

    .action-sheet-button.sc-ion-action-sheet-md {
        font-size: 20px !important;
    }

    .native-input.sc-ion-input-md {
        font-size: 20px !important;
    }

    ionic-selectable {
        font-size: 20px !important;
    }

    .alert-input-group,
    .alert-message {
        font-size: 20px !important;
    }

    .nachricht-absender,
    .nachricht-datum {
        font-size: 17px !important;
    }

    .nachricht-text {
        font-size: 20px !important;
    }
}

@media (min-width: 768px) {
    h1.kommissionieren-palette-titel {
        font-size: 24px !important;
    }

    // .kommissionieren-oberauftrag-detail2 {
    //     position: absolute;
    //     right: 15px;
    //     top: 15px;
    //     padding: 10px;
    //     border: 1px solid black;
    //     font-size: 32px !important;
    // }
}


.design-standard,
.design-v2 {
    ion-button {
        font-weight: 400;
    }
}

ion-button.main-item h1 {
    font-weight: 400;
}

ion-button.main-item.ion-color-light {
    // Die Buttons auf der Startseite sollen weiß sein
    --ion-color-base: #fff !important;
}



.layout-kacheln {
    ion-button.main-item {
        width: calc(50% - 20px);

        i.material-icons,
        ion-icon {
            left: unset;
        }
    }
}

// .layout-standard {
//     ion-button.main-item {
//         --border-radius: 15px;
//         border-radius: 15px !important;
//         box-shadow: none;

//         button.button-native {
//             --border-radius: 15px;
//             border-radius: 15px !important;
//         }
//     }

//     :host(.button-full:not(.button-round)) .button-native {
//         border-radius: 15px !important;
//     }
// }

.ion-color-plus {
    --ion-color-base: #6FC757 !important;
    --ion-color-base-rgb: 111, 199, 87 !important;
    --ion-color-contrast: #000 !important;
    --ion-color-contrast-rgb: 0, 0, 0 !important;
    // --ion-color-shade: var(--ion-color-success-shade, #d7d8da) !important;
    // --ion-color-tint: var(--ion-color-success-tint, #f5f6f9) !important;
    --ion-color-shade: #d7d8da !important;
    --ion-color-tint: #f5f6f9 !important;
}

.ion-color-minus {
    --ion-color-base: #D74C47 !important;
    --ion-color-base-rgb: 215, 76, 71 !important;
    --ion-color-contrast: #FFF !important;
    --ion-color-contrast-rgb: 255, 255, 255 !important;
    --ion-color-shade: var(--ion-color-danger-shade, #d7d8da) !important;
    --ion-color-tint: var(--ion-color-danger-tint, #f5f6f9) !important;
}

ion-label.label-stacked {
    font-size: 17px !important;
    -webkit-transform: none !important;
    transform: none !important;
    -webkit-transition: none !important;
    transition: none !important;
}

ion-item.item-label-stacked {
    margin-top: 8px;
    margin-bottom: 15px;
    --padding-end: 0;
    --inner-padding-end: 0;

    ion-input {
        --padding-start: 10px !important;
        border: 1px solid #ddd;
    }

    ion-input.display-input {
        --padding-start: 0 !important;
        border: 0;
    }

    ion-datetime {
        --padding-start: 10px !important;
        border: 1px solid #ddd;
    }

    ion-button {
        height: 44px;
        margin-top: 20px; // auto
        margin-bottom: 0;
        margin-left: 2px;
    }

    .ionic-selectable {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        height: 42px;
        line-height: 26px;
        border: 1px solid #ddd;
    }

    ion-select {
        padding-left: 10px;
        padding-right: 10px;
        border: 1px solid #ddd;
    }
}

.input-select-item {
    --inner-padding-end: 0;

    ion-label {
        max-width: 100px;
    }

    ion-select {
        width: calc(100% - 100px) !important;
        max-width: 300px !important;
        margin-left: 0;
        border: 0 !important;
        --min-height: 48px;
        height: 48px;
        position: relative;
        padding-right: 10px;
        background-color: #eee;
    }
}

ion-button.plusminus-button {
    margin-bottom: 10px;
    margin-left: 2px;
    --padding-start: 18px;
    --padding-end: 14px;

    // Test
    // border: 0 !important;
    // font-size: 38px !important; // important. Darf nicht über die Schriftgröße überschrieben werden
    // --ion-color-base: white !important;
    font-weight: 300;

    border: 0 !important;
    box-shadow: none !important;

    border-radius: 50%;
    --border-radius: 50%;
    height: 48px;
    width: 38px;
    padding-top: 4px;
    padding-bottom: 4px;
    --color: #05295D;
    font-size: 28px !important;
}

ion-button.plusminus-button.button-default {
    // Muss hier nochmal überschrieben werden. Ansonsten wird ein Rand angezeigt
    border: 0 !important;
    box-shadow: none !important;
}

// ion-button.plusminus-button.plus-button {
//     --ion-color-contrast: green !important;
// }

// ion-button.plusminus-button.minus-button {
//     --ion-color-contrast: red !important;
// }

ion-button.inline-plusminus-button.button-default {
    margin-bottom: 10px;
    height: 48px;
    width: 48px;
    margin-left: 2px;
    font-size: 24px;
    --padding-start: 18px;
    --padding-end: 14px;
    border: 0 !important;
    font-size: 38px !important; // important. Darf nicht über die Schriftgröße überschrieben werden
    box-shadow: none !important;
    --ion-color-base: white !important;
    font-weight: 300;
}

.plus-button {
    --ion-color-base: #CCFFCC !important;
    margin-left: 4px;
}

.minus-button {
    --ion-color-base: #FFCCCC !important;
    margin-right: 4px;
}

// .schriftgroesse-sehr-gross



// ion-input,
// ionic-selectable,
// ion-datetime {
//     --padding-bottom: 8px;
//     // --padding-end: 0;
//     // --padding-start: 0;
//     --padding-top: 8px;
// }

//.label-stacked.sc-ion-label-md-h {
/*  left top; */
/* transform-origin: left top; */
/* -webkit-transform: translate3d(0,50%,0) scale(.75); */
/* transform: translate3d(0,50%,0) scale(.75); */
/* -webkit-transition: color .15s cubic-bezier(.4,0,.2,1); */
/* transition: color .15s cubic-bezier(.4,0,.2,1); */
//}

.color-green {
    color: #43A047;
}

.color-blue {
    color: #039BE5;
}

.color-hofauftraege {
    color: #404040;
}

.color-kommissionieren {
    color: blueviolet;
}

.color-mail {
    color: #FFD494;
}

.color-uvv {
    color: #005C99;
}

.color-uvv.color-uvv-inactive {
    color: #A3A3A3;
}

.color-home {
    color: #4FCC4B;
}

.color-folder {
    color: #FCB459;
}

.color-exit {
    color: #555555;
}

.color-settings {
    color: #78909C;
}

.color-login {
    color: #039BE5;
}

.color-uebersicht {
    color: #039BE5;
}

.color-benutzereinstellungen {
    color: #039BE5;
}

.color-watch {
    color: #00AEFF;
}

.color-proglove {
    color: #ED7003;
}

.color-pause {
    color: orange;
}

.alert-sub-title.sc-ion-alert-md {
    white-space: pre-wrap;
}

.alert-message.sc-ion-alert-md {
    max-height: 1000px;
}

// Auswahl-Popups sollen viel größer sein
ion-alert.single-select-alert {
    .alert-wrapper.sc-ion-alert-md {
        height: 95%;
        width: 95%;
        --max-height: 95%;
        --max-width: 95%;

        div.alert-radio-group.sc-ion-alert-md {
            --height: 100%;
            --max-width: 100%;
            max-height: unset;
            height: 100%;
        }
    }

    .sc-ion-alert-md-h {
        --max-width: 95%;
    }
}

.action-sheet-group.sc-ion-action-sheet-ios {
    border-radius: 0;
}

ion-toast {
    --start: 15px;
    --end: 15px;
    --border-radius: 0;
}

.sc-ion-alert-md {
    .alert-button-group {
        border-top: 1px solid #999;
        padding: 0;

        button {
            width: calc(50% - 8px);
            height: 56px;
            border-left: 1px solid #999;

            span.alert-button-inner {
                text-align: center;
                display: inline-block;
                line-height: 36px;
            }
        }
    }
}

.no-wrap {
    white-space: nowrap;
}

.ansicht-phone {
    display: table;
}

// @media (min-width: 768px) {
//     .ansicht-phone {
//         display: none !important;
//     }

//     .ansicht-tablet {
//         display: block;
//     }
// }

// @media (max-width: 767px) {
//     .ansicht-phone {
//         display: block;
//     }

//     .ansicht-tablet {
//         display: none !important;
//     }
// }


.design-standard,
.design-v2 {
    ion-button {
        // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        box-shadow: none;
        // transition: all 2.3s cubic-bezier(.25, .8, .25, 1);
    }

    ion-button.ion-color-light {
        border: 1px solid #ddd;
    }

    ion-button:hover {
        // box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }

    ion-button.button-clear {
        box-shadow: none;
    }
}

.rest-dauer-abfahrtkontrolle {
    text-align: center;
    // padding: 0 20px;
    font-size: 26px;
}

.input-upper-case {
    text-transform: uppercase;
}

.wizard-buttons {
    padding-bottom: 5px;

    .button3 {
        width: calc(33% - 5px);
        display: inline-block;
        height: 60px;
    }

    .button50,
    .button2 {
        width: calc(50% - 5px);
        display: inline-block;
        height: 60px;
    }
}

.unterschrift-container {
    border-radius: 0;
    padding: 15px;
    // border: 1px dashed #999;
    text-align: center;

    img {
        max-height: 20vh;
    }
}

.unterschrift-container.erforderlich {
    background-color: #ff9999;
    border: 1px dashed #999;
}

.unterschrift-container.optional {
    border: 1px dashed #999;
}

.unterschrift-container.vorhanden {
    background-color: white;
    // color: #468847;
    text-align: left;
    border: 1px solid #999;
    border-radius: 0;

    img {
        min-height: 20px;
        // max-height: 50px;
    }
}

.wizard-content {
    .wizard-content-inner {
        min-height: calc(100vh - 165px);
    }

    .fab-vertical-bottom {
        bottom: 110px;
    }

    .buttons-section {
        padding-bottom: 5px;
    }
}

ion-alert.no-title-alert {
    .alert-head {
        display: none;
    }
}

ion-alert.no-text-alert {
    .alert-message {
        display: none;
    }
}

.section-no-padding {
    padding: 0;
}

.status-button {
    margin-bottom: 10px;
    height: 64px;
}

@media (min-width: 300px) {
    .bild-container {
        width: 50%;
        display: inline-block;
    }
}

@media (min-width: 768px) {

    // TABLE-LAYOUT: Buttons nebeneinander darstellen
    .buttons-section {
        ion-button {
            display: inline-block;
            min-width: 200px;
            height: 84px;
            font-size: 24px;
        }
    }

    .bild-container {
        display: inline-block;
        width: 33%;
        vertical-align: middle;
    }

    .auftragstyp-kommissionierung,
    .auftragstyp-beladung {
        .section-unterauftraege {
            padding-top: 0;
        }

        // Buttons sollen rechts oben dargestellt werden
        .buttons-section-top {
            display: block;
            position: absolute;
            top: 5px;
            right: 15px;
            z-index: 1000;
            padding: 0;
            border: 0;
            max-width: 65%;
            text-align: right;
            background-color: transparent;

            ion-button {
                min-width: 60px;
                font-size: 18px;
            }
        }

        .buttons-section-normal {
            display: none;
        }

        .section {
            border: 0;
            margin-bottom: 0;
        }
    }
}


@media (max-width: 767px) {

    .auftragstyp-kommissionierung,
    .auftragstyp-beladung {
        .buttons-section-top {
            display: none;
        }

        .buttons-section-normal {
            display: block;
        }
    }
}

.adresse-outer {
    background-color: white;
}

.unbekannte-komponente {
    color: #999;
    padding-bottom: 5px;
}

ion-list.no-lines {
    ion-item {
        --min-height: 24px;
        --box-shadow: 0;
        --border-color: transparent;

        ion-label {
            margin: 0;
        }
    }
}

.section-content-white {
    .auftragsposition.section {
        border-bottom: 5px solid #ddd;
    }
}

.anzeigebild {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 1000;

    pinch-zoom {
        max-height: 100%;
        background-color: white !important;
        height: 100%;
    }

    ion-button {
        position: fixed;
        bottom: 15px;
        right: 15px;
        color: red;
        z-index: 1000;
    }
}

.statusmeldungen-alert {
    .alert-button-group {
        overflow-y: scroll;
        flex-wrap: nowrap;
    }
}

ion-popover.stoerzeit-popover,
ion-popover.wareneingang-popover {
    --width: 90%;
    --max-width: 400px;
}

.toolbar-title {
    font-weight: 300;
}

#page-title-span {
    font-weight: 300;
}

ion-badge {
    font-weight: 400;
    border-radius: 0;
}

.preLine {
    white-space: pre-line;
}

img.avatar-img {
    border-radius: 50%;
}

ion-button.button48 {
    width: 48px;
    height: 48px;
    --padding-start: 0;
    --padding-end: 0;
}

.select-interface-option.favorite {
    font-weight: 500;

    .alert-radio-label {
        color: $blau;
        font-size: large;
    }
}

span.neue-nummer {
    display: block;
    margin-top: 10px;
    font-size: 24px;
}

ion-item.display-only {
    .input-outer {
        ion-input {
            background-color: transparent !important;
        }

        .einheit {
            background-color: transparent !important;
        }
    }
}

.debug-info {
    display: none;
}

.input-small-clear-button {
    .clear-icon {
        margin-left: 5px !important;
        margin-right: 0px !important;
    }
}

.palette-status-Vollpalette {
    margin-top: 10px;
    color: blue;
    font-weight: 500;
}

.palette-status-BereitsVerladen {
    margin-top: 10px;
    color: green;
    font-weight: 500;
}

.edit-leergewicht-button {
    color: #aaa;
    vertical-align: middle;
    --padding-start: 0;
    --padding-end: 0;
}

.arbeitszeitart-standard {
    color: green !important;
    font-weight: 500;
}

.arbeitszeitart-other {
    color: #333 !important;
}

.wiegung-ausgabe {
    margin-top: 10px;
    border: 1px solid #ccc;
    padding: 15px;
    width: 100%;
    font-size: 18px;
    user-select: text;
}

app-input-textarea-popup {
    .textarea-wrapper {
        height: 100%;

        textarea {
            height: 100%;
        }
    }
}


.lieferung-table {
    .checkbox-disabled {
        opacity: 0.7;
    }
}

body.dark {
    color-scheme: dark;

    // https://ionicframework.com/docs/theming/dark-mode
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    ion-toolbar {
        --background: #000000 !important;
        --color: red !important;
    }

    .layout-standard {
        ion-button.main-item.ion-color-light {
            // Die Buttons auf der Startseite sollen weiß sein
            --ion-color-base: #262626 !important;
            --ion-color-contrast: #fff !important;
        }
    }

    .layout-kacheln {
        ion-button.main-item.ion-color-light {
            // Die Buttons auf der Startseite sollen weiß sein
            --ion-color-base: #000 !important;
            --ion-color-contrast: #fff !important;
        }
    }

    .home-content {
        --background: #000;
    }

    --ion-background-color: #000;
    --background: #000;
    --color: #fff !important;
    --ion-item-color: #fff !important;
    --ion-text-color: #fff !important;

    --ion-color-primary: #999;

    .section {
        --ion-background-color: #000;
        background-color: #000;
    }

    ion-item.item-label-stacked {
        ion-input {
            border-color: #666;
        }

        ion-label {
            background: black;
        }
    }

    --ion-color-step-600: #ddd;

    ion-content.section-content {
        --ion-background-color: #000; // #333333;
    }

    // Fehlerhafte Eingabefelder
    ion-input.ion-invalid,
    ion-select.ion-invalid,
    ion-input.invalid-value {
        background-color: #3D1A17 !important;
        color: white;
        border-color: red !important;
        border: 1px solid red;
    }

    .input-select-item {
        ion-select {
            background-color: #1E1E1E;
        }
    }

    ion-item-divider {
        color: white !important;
        background-color: #363636 !important;

        .subtitle {
            color: white !important;
        }
    }

    .alert-wrapper.sc-ion-alert-md {
        border: 1px solid #555;
    }


    .anzeigebild {
        background-color: #000;

        pinch-zoom {
            background-color: #000 !important;
        }
    }

    .alert-button.sc-ion-alert-md {
        color: white;
    }

    .alert-wrapper.sc-ion-alert-ios {
        border: 1px solid #444;
        --ion-color-step-600: white;

        .alert-button {
            color: white;
        }
    }

    ion-footer ion-toolbar {
        color: white;
    }

    .fotos-container {
        ion-list {
            // Hintergrund von Fotos soll schwarz sein
            --ion-item-background: black;
        }
    }

    .unterschrift-container.erforderlich {
        background-color: #ff5b71;
    }

    auftrag-statusmeldungen-komponente,
    auftrag-dateien-komponente {
        ion-list {
            --ion-item-background: black;
            --ion-background-color: black;
        }
    }

    .color-hofauftraege {
        color: #a6a6a6;
    }

    .color-exit {
        color: #a6a6a6;
    }

    .info1 {
        span {
            background-color: yellow;
            color: black;
        }
    }

    .arbeitszeitart-standard {
        color: #2fdf75 !important;
    }

    .arbeitszeitart-other {
        color: white !important;
    }
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */

.ios body.dark {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
}

.ios body.dark ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
    --ion-item-background: var(--ion-color-step-150);
}

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body.dark {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
}

body.dark {
    img.dark-invert {
        filter: invert(1);
    }
}

.status-item-outer {
    .extraText2 {
        margin-top: 7px;
    }
}

.hidden {
    display: none;
}

hr.hr {
    background-color: lightgray;
    margin-top: 25px;
    margin-bottom: 25px;
    height: 1px;
    border-width: 0;
}

ion-list {

    // Runde Buttons in Listen
    // Wird verwendet für die Stopp-Buttons in der Arbeitszeiterfassung (Tour + Auftrag)
    ion-button.list-action-button {
        width: 48px; //calc(33% - 4px);
        height: 48px;
        border-radius: 50%;
        font-size: 20px;

        --padding-start: 8px;
        --padding-end: 8px;
    }

    // Der Ende-Button in der Arbeitszeiterfassung
    ion-button.ende-button {
        --ion-color-base: #E87161 !important;

        ion-icon {
            margin-right: 0px;
            color: white !important;
        }
    }
}

ion-content {
    // .ion-content-scroll-host {
    //     height: 100%;
    //     width: 100%;
    // }

    cdk-virtual-scroll-viewport {
        height: 100%;
        width: 100%;
    }

    // Das wird benötigt, damit ion-refresher mit cdk-virtual-scrolling richtig funktioniert
    // https://ionicframework.com/docs/api/refresher
    .ion-content-scroll-host::before,
    .ion-content-scroll-host::after {
        position: absolute;

        width: 1px;
        height: 1px;

        content: '';
    }

    .ion-content-scroll-host::before {
        bottom: -1px;
    }

    .ion-content-scroll-host::after {
        top: -1px;
    }

    .cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
        // Max-Width ist erforderlich, ansonsten werden die Texte in der Auftragsliste rechts abgeschnitten (overflow)
        max-width: 100%;
    }
}